import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { dentalCenter } from '../../models/dentalCenters';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class DentalCenterService {

  constructor(private http:HttpClient) { 

  }
   getId(id:number){
    return this.http.post<any>(environment.url+"/dentalCenter/getId",{id:id});
   }
  
   setup(param:[]){
    return this.http.post<any>(environment.url+"/dentalCenter/setup",param);
   }
   updateDoctor(param:[]){
    return this.http.post<any>(environment.url+"/dentalCenter/updateDoctor",param);
   }
   getIdWithClint(id:number,doctorId:number){
    return this.http.post<any>(environment.url+"/dentalCenter/getIdWithClint",{id:id,doctorId:doctorId});
   }
}
