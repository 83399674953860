import { Component } from '@angular/core';
import { StlViewService } from './core/services/stlView/stl-view.service';

@Component({
  selector: 'app-ubold',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private stlView:StlViewService){
  }

  StopAnmtion(){
    this.stlView.StopAnmtion();
  }
  
  closeStlViewr(){
    this.stlView.StopAnmtion();
    this.stlView.clearStl();
    (<HTMLDivElement>document.getElementById("modelDivStlViewr")).style.display="none";
  }
}
