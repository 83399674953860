export const environment = {
  production: false,
  trans:null,
  token:String,
  url:'https://api.parissmily.com',
  langId:1,
  id:2,
  langCode:'EN',
  typeUser:'2',
  dentalCenterId:-1
};
